<template>
  <b-card :title="$t('automationFileSettings')">
    <b-form>
      <b-form-textarea v-model="fileSettings" rows="10"></b-form-textarea>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 mr-1"
        @click="updateCompany"
      >
        {{ $t("save") }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import { BButton, BForm, BFormTextarea, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BForm,
    BFormTextarea,
    BCard,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      fileSettings: `<FORMULKODU:5 />
<FORMULADI:50 />
<TARIH:dd-MM-yyyy/>
<FORMULTOPLAMI:5:2 />
<TIP:1/>
<HAMMADDE:>
  <HAMMADDEKODU:8:L:/>
  <HAMMADDEADI:30:L:/>
  <HAMMADDEMIKTARI:5:2/>
</HAMMADDE:>  
<OZEL></OZEL>`,
    };
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData));
    },
  },
};
</script>
