<template>
  <!-- <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  > -->
  <!-- general tab -->
  <!-- <b-tab active>
       title -->
  <!-- <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("general") }}</span>
      </template>

    </b-tab> -->
  <!--/ general tab -->

  <!-- change password tab -->
  <!-- <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("tableSettings") }}</span>
      </template>

      <table-settings />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("otomationFileSettings") }}</span>
      </template>

      <otomation-file-settings />
    </b-tab> -->
  <!--/ change password tab -->
  <!-- </b-tabs> -->
  <company-setting-general v-if="options" :general-data="options" />
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import CompanySettingGeneral from "./CompanySettingGeneral.vue";
import TableSettings from "./TableSettings.vue";
import OtomationFileSettings from "./OtomationFileSettings.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    // BTabs,
    // BTab,
    CompanySettingGeneral,
    // TableSettings,
    // OtomationFileSettings,
  },
  data() {
    return {
      options: null,
    };
  },

  beforeCreate() {
    var currentUser = getUserData();
    var users = {};
    this.$http.get(`/Users/GetTinyUsers`).then(({ data }) => {
      users = data.resultdata.filter((user) => user.id == currentUser.id)[0];
      this.$http
        .get("/Plants/GetUsersPlantsWithAll", {
          companyid: currentUser.companyid,
          useridtoselect: currentUser.id,
        })
        .then((res) => {
          this.options = users;
          this.options.companyname =
            currentUser.companyname == undefined ? "" : currentUser.companyname;
          this.options.ids = res.data.resultdata.map((ids) => ids.id);
        });
    });
  },
};
</script>
