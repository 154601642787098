<template>
  <div>
    <company-settings></company-settings>
  </div>
</template>

<script>
import CompanySettings from "./CompanySetting.vue";
export default {
  components: {
    CompanySettings,
  },
  mounted() {},
};
</script>

<style></style>
