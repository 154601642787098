<template>
  <b-card :title="$t('tableSettings')">
    <b-form-group
      v-slot="{ ariaDescribedby }"
      label="Table Options"
      label-cols-lg="2"
    >
      <b-form-checkbox
        v-model="striped"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("striped") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="bordered"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("bordered") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="borderless"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("borderless") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="outlined"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("outlined") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="small"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("small") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="hover"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("hover") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="dark"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("dark") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="fixed"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("fixed") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="footClone"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("footClone") }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="noCollapse"
        :aria-describedby="ariaDescribedby"
        inline
      >
        {{ $t("noCollapse") }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      v-slot="{ ariaDescribedby }"
      label="Head Variant"
      label-cols-lg="2"
    >
      <b-form-radio-group
        v-model="headVariant"
        :aria-describedby="ariaDescribedby"
        class="mt-lg-2"
      >
        <b-form-radio :value="null" inline>None</b-form-radio>
        <b-form-radio value="light" inline>Light</b-form-radio>
        <b-form-radio value="dark" inline>Dark</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      label="Table Variant"
      label-for="table-style-variant"
      label-cols-lg="2"
    >
      <b-form-select
        id="table-style-variant"
        v-model="tableVariant"
        :options="tableVariants"
      >
        <template #first>
          <option value="">-- None --</option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-table
      :striped="striped"
      :bordered="bordered"
      :borderless="borderless"
      :outlined="outlined"
      :small="small"
      :hover="hover"
      :dark="dark"
      :fixed="fixed"
      :foot-clone="footClone"
      :no-border-collapse="noCollapse"
      :items="items"
      :fields="fields"
      :head-variant="headVariant"
      :table-variant="tableVariant"
    ></b-table>
    <b-button variant="primary" class="mt-2 mr-1" @click="updateCompany">
      {{ $t("save") }}
    </b-button>
  </b-card>
</template>

<script>
import {
  BTable,
  BCard,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BFormRadio,
  BFormRadioGroup,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BTable,
    BCard,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BFormRadio,
    BFormRadioGroup,
    BButton,
  },

  data() {
    return {
      fields: ["colon1", "colon2"],
      items: [
        { colon1: "Normal Satır 1", colon2: "Normal Satır 1" },
        { colon1: "Alternatif Satır 2", colon2: "Alternatif Satır 2" },
        { colon1: "Normal Satır 3", colon2: "Normal Satır 3" },
        { colon1: "Alternatif Satır 4", colon2: "Alternatif Satır 4" },
        { colon1: "Normal Satır 5", colon2: "Normal Satır 5" },
      ],
      tableVariants: [
        { value: "primary", text: this.$t("primaryTable") },
        { value: "secondary", text: this.$t("secondaryTable") },
        { value: "info", text: this.$t("infoTable") },
        { value: "danger", text: this.$t("dangerTable") },
        { value: "warning", text: this.$t("warningTable") },
        { value: "success", text: this.$t("successTable") },
        { value: "light", text: this.$t("lightTable") },
        { value: "dark", text: this.$t("darkTable") },
      ],
      striped: false,
      bordered: false,
      borderless: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      headVariant: null,
      tableVariant: "osman",
      noCollapse: false,
    };
  },
  methods: {
    updateCompany() {
      this.$store.dispatch("updateCompany", this.company);
    },
  },
};
</script>
